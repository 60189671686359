/* src/App.css */
.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    background-color: white;
    margin: 0;
    padding: 0;
  }
  