/* src/components/EnrollPage.css */

.enroll-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100svh;
    width: 100%;
  }

  .enroll-page-intro {
    background-color: white;
    width: 100vw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .logo-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 2s ease, transform 2s ease;
  }
  
  .logo-section.to-top {
    top: 5vh;
    transform: translate(-50%, 0);
  }
  
  .logo-section img {
    width: 200px;
    height: 200px;
    transition: width 2s ease, height 2s ease;
  }
  
  .logo-section h1 {
    transition: font-size 2s ease;
  }
  
  .logo-section.to-top h1 {
    font-size: 1.2rem;
  }
  
  .start-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100svh;
    text-align: left;
  }
  
  .start-section.show {
    display: flex;
  }
  
  .start-section p {
    font-family: 'Roboto Flex';
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  
  .enroll-image {
    width: 150px;
    height: 150px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .selected-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .selected-image {
    width: 200px;
    height: 200px;
  }
  
  .image-text {
    font-family: 'Roboto Flex';
    display: flex;
    margin-top: 10px;
  }
  
  .char-span {
    font-size: 72px;
    font-weight: bold;
    margin: 0 15px; /* Adjust the value to increase or decrease spacing */
  }
  
  .or-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
  }
  
  .or-divider .line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
  }
  
  .or-divider span {
    font-size: 1.2em;
    color: #666;
  }

  .custom-word-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .custom-word-input input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
    margin-bottom: 10px;
  }
  
  .custom-word-input button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }