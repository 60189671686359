/* src/components/AdminPage.css */
.admin-page {
    background-color: white;
    width: 100vw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
  .logo-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 2s ease, transform 2s ease;
    justify-content: center;
  }
  
  .logo-section.to-top {
    top: 5vh;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  .logo-section img {
    width: 200px;
    height: 200px;
    transition: width 2s ease, height 2s ease;
  }
  
  .logo-section h1 {
    transition: font-size 2s ease;
  }
  
  .logo-section.to-top h1 {
    font-size: 1.2rem;
  }
  
  .start-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100svh;
  }
  
  .start-section.show {
    display: flex;
  }
  
  .start-section p {
    font-family: 'Roboto Flex';
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }

.user-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    overflow-x: auto;
    display: block;
}
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .admin-page {
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      height: 100svh; /* Ensure full height on mobile */
    }
  }
  