/* src/components/LoginPage.css */
.login-page {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
  .logo-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 2s ease, transform 2s ease;
  }
  
  .logo-section.to-top {
    top: 5vh;
    transform: translate(-50%, 0);
  }
  
  .logo-section img {
    width: 200px;
    height: 200px;
    transition: width 2s ease, height 2s ease;
  }
  
  .logo-section h1 {
    transition: font-size 2s ease;
  }
  
  .logo-section.to-top h1 {
    font-size: 1.2rem;
  }
  
  .login-section {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
  }
  
  .login-section.show {
    display: flex;
  }
  
  .login-section p {
    font-family: 'Roboto Flex';
    font-size: 18px;
    margin-bottom: 20px;
  }

  .login-input {
    font-family: 'Roboto Flex';
    font-size: 16px;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    border: 2px solid grey; /* Ensures consistent border style */
    border-radius: 10px;
    outline: none; /* Removes default outline on focus */
    box-shadow: none; /* Removes any box shadow */
}

.button-container {
    display: flex;
    flex-direction: column;
}

.button-container button {
    margin: 10px;
}
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .login-page {
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      height: 100%; /* Ensure full height on mobile */
    }
  }
