/* src/components/HomePage.css */
.thanks-page {
    background-color: white;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  #thanks-img {
    width: 150px;
    height: 150px;
  }
  
  .logo-section-no-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 0s ease, transform 0s ease;
  }
  
  .start-section-thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    text-align: center;
  }
  
  .start-section-thanks.show {
    display: flex;
  }
  
  .start-section-thanks p {
    font-family: 'Roboto Flex';
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .home-page {
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      height: 100%; /* Ensure full height on mobile */
    }
  }
  