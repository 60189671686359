/* src/components/BigButton.css */
.button-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .big-button {
    font-family: 'Roboto Flex';
    background-color: #4C1B92;
    border: none;
    color: white;
    padding: 10px 75px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 15px;
    cursor: pointer;
    margin: 0 5px;
    width: 200px;
    position: relative;
    overflow: hidden;
  }
  
  .big-button:hover {
    background-color: #986fd1;
  }
  
  .big-button.coming-soon {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .coming-soon-tape {
    position: absolute;
    top: 86%;
    left: -25%;
    width: 150%;
    padding: 2px 0;
    background-color: #ffcc00;
    color: #333;
    font-size: 6px;
    font-weight: bold;
    text-align: center;
    transform: rotate(-0deg) translateY(-50%);
    transform-origin: center;
    pointer-events: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
