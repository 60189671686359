.logout-button {
    position: absolute;
    top: 25px;
    left: 25px;
    padding: 0;
    border: none; /* Remove border */
    background: none; /* Remove background */
    cursor: pointer; /* Optional: Add pointer cursor */
    z-index: 10;
  }
  
  .logout-button img {
    width: 25px;
    height: 25px;
  }
  