/* src/components/DrawPage.css */

.draw-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100svh;
    width: 100vw;
  }
  
  .drawing-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .fullscreen {
    width: 100%;
    height: 100%;
  }
  
  .text-container {
    font-family: 'Roboto Flex';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .text-explainer {
    position: relative;
    top: 15%;
  }
  
  .char-span-large {
    font-size: 50vh;
  }
  
  #draw-buttons {
    display: flex;
    position: absolute;
    bottom: 20px;
  }

  #draw-buttons button {
    font-family: 'Roboto Flex';
    background-color: #4C1B92;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin: 1px 5px;
    z-index: 3;
  }

  .hidden {
    display: none !important;
  }

  .image-gallery {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .saved-image {
    width: 30px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    object-fit: contain;
  }